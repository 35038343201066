import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from '@mui/material';
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
import { useNavConfig } from '../../../components/nav-section/vertical/config-navigation';
import { NAV } from '../../../config-global';
import { hideScrollbarX } from '../../../utils/cssStyles';
import NavToggleButton from './NavToggleButton';
// ----------------------------------------------------------------------
export default function NavMini() {
    const navConfig = useNavConfig();
    return (_jsxs(Box, { component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD_MINI },
        }, children: [_jsx(NavToggleButton, { sx: {
                    top: 22,
                    left: NAV.W_DASHBOARD_MINI - 12,
                } }), _jsxs(Stack, { sx: {
                    pb: 2,
                    height: 1,
                    position: 'fixed',
                    width: NAV.W_DASHBOARD_MINI,
                    borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    ...hideScrollbarX,
                }, children: [_jsx(Logo, { sx: { mx: 'auto', my: 2 } }), _jsx(NavSectionMini, { data: navConfig })] })] }));
}
